//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      success: false,
      error: false
    };
  },
  created() {
    if (this.logged) {
      this.loadTGBot();
    } else {
      this.login("Login");
    }
  },
  computed: {
    logged() {
      return this.$store.state.user.user;
    },
    tgBotId() {
      return this.$route.params.id;
    }
  },
  methods: {
    async loadTGBot() {
      try {
        await this.$store.dispatch("user/loadTGBot", {
          id: this.tgBotId
        });
        this.success = true;
        this.error = false;
      } catch (e) {
        this.success = false;
        // if (e.response?.status === 404) {
        this.error = true;
        // }
      }
    },

    login(name) {
      this.$router.push({
        name,
        query: {
          backUrl: `/addtgbot/${this.tgBotId}`
        }
      });
    }
  }
};